import axios, { AxiosRequestConfig } from 'axios';

const requestTimeoutInterceptor = (config) => {
  if (config.timeout === undefined || config.timeout === 0) {
    return config;
  }

  const source = axios.CancelToken.source();

  setTimeout(() => {
    source.cancel('TIMEOUT');
  }, config.timeout);

  // If caller configures cancelToken, preserve cancelToken behaviour.
  if (config.cancelToken) {
    config.cancelToken.promise.then((cancel) => {
      source.cancel(cancel.message);
    });
  }

  return { ...config, cancelToken: source.token };
};

class HttpRequest {
  axiosInstance = null;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });

    this.axiosInstance.defaults.timeout = 6000; // 6 seconds

    this.axiosInstance.interceptors.request.use((request) => {
      return request;
    });

    this.axiosInstance.interceptors.request.use(requestTimeoutInterceptor);

    /**
     * catch error from server's response
     */
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (typeof window !== 'undefined') {
          console.warn('Receiving response', response);
        }
        return response;
      },
      (error) => {
        return Promise.reject(error.response || error);
      },
    );

    this.setHeader();
  }

  setHeader = () => {
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';
  };

  get = (url: string, config?: AxiosRequestConfig) => this.axiosInstance.get(url, config);

  post = (url: string, data?: any, config?: AxiosRequestConfig): Promise<any> =>
    this.axiosInstance.post(url, data, config);

  put = (url: string, data: any, config?: AxiosRequestConfig) => this.axiosInstance.put(url, data, config);

  del = (url: string, config?: AxiosRequestConfig) => this.axiosInstance.delete(url, config);

  patch = (url, data) => this.axiosInstance.patch(url, data);
}

export default HttpRequest;
