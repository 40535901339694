// eslint-disable-next-line max-classes-per-file, no-unused-vars, @typescript-eslint/no-unused-vars
import { Entity, SafeType, Transform } from 'dto-mapping';

/* eslint-disable no-unused-vars */
export enum Tools {
  EAS = 'EAS',
  VAS = 'VAS',
  PTS = 'PTS',
}
/* eslint-enable no-unused-vars */

export type DemoRequestArg = {
  email: string;
  describe?: string;
  lang: string;
  customerName: string;
  userName: string;
  tools: Set<Tools>;
};

export type PartnerRequestArg = {
  email: string;
  userName: string;
  customerName: string;
  lang: string;
  jobTitle: string;
  phoneNumber: string;
};

@Entity()
class DemoRequest {
  @SafeType({ type: String })
  email: string;

  @SafeType({ type: String })
  describe: string;

  @SafeType({ type: String })
  lang: string;

  @SafeType({ type: String })
  @Transform({
    fn: (model: any) => model.customerName,
  })
  customerName: string;

  @SafeType({ type: String })
  @Transform({
    fn: (model: any) => model.userName,
  })
  userName: string;

  @SafeType({ type: String })
  @Transform({
    fn: (model: DemoRequestArg) => Array.from(model.tools.values()),
  })
  tools: Tools[];

  // eslint-disable-next-line
  constructor(_: DemoRequestArg) {}

  getData() {
    return {
      email: this.email,
      describe: this.describe,
      customer_name: this.customerName,
      user_name: this.userName,
      lang: this.lang,
      tools: this.tools,
    };
  }
}

@Entity()
class PartnerRequest {
  @SafeType({ type: String })
  email: string;

  @SafeType({ type: String })
  jobTitle: string;

  @SafeType({ type: String })
  phoneNumber: string;

  @SafeType({ type: String })
  lang: string;

  @SafeType({ type: String })
  @Transform({
    fn: (model: PartnerRequestArg) => model.customerName,
  })
  customerName: string;

  @SafeType({ type: String })
  @Transform({
    fn: (model: PartnerRequestArg) => model.userName,
  })
  userName: string;

  // eslint-disable-next-line
  constructor(_: PartnerRequestArg) {}

  getData() {
    return {
      email: this.email,
      customer_name: this.customerName,
      user_name: this.userName,
      lang: this.lang,
      job_title: this.jobTitle,
      phone_number: this.phoneNumber,
    };
  }
}

export type JoinFormArg = {
  email: string;
  companyName: string;
  lastName: string;
  firstName: string;
  jobTitle: string;
  country: string;
  program: string;
  source?: string;
  lang: string;
  additionalInfo?: string;
  industry?: string;
  tools?: string[];
};

@Entity()
class JoinFormRequest {
  @SafeType({ type: String })
  email: string;

  @SafeType({ type: String })
  lang: string;

  @SafeType({ type: String })
  companyName: string;

  @SafeType({ type: String })
  lastName: string;

  @SafeType({ type: String })
  firstName: string;

  @SafeType({ type: String })
  jobTitle: string;

  @SafeType({ type: String })
  country: string;

  @SafeType({ type: String })
  program: string;

  @SafeType({ type: String })
  source: string;

  @SafeType({ type: String })
  additionalInfo: string;

  @SafeType({ type: String })
  industry: string;

  @SafeType({ type: String })
  tools: Tools[];

  // eslint-disable-next-line
  constructor(_: JoinFormArg) {}

  getData() {
    return {
      email: this.email,
      customer_name: this.companyName,
      first_name: this.firstName,
      last_name: this.lastName,
      job_title: this.jobTitle,
      country: this.country,
      chosen_program: this.program,
      source: this.source,
      additional_info: this.additionalInfo,
      lang: this.lang,
      industry: this.industry,
      tools: this.tools,
    };
  }
}

export { DemoRequest, PartnerRequest, JoinFormRequest };
