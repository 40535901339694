import HttpRequest from '../utils/axios';
import { LANDING_API } from '../constants/domains';

let httpInstance = new HttpRequest(LANDING_API);

const setInstanceUrl = (url: string): HttpRequest => {
  httpInstance = new HttpRequest(url);
  return httpInstance;
};

const getInstance = (): HttpRequest => {
  return httpInstance;
};

export default getInstance;
export { setInstanceUrl };
