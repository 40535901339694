import { DemoRequest, JoinFormArg, JoinFormRequest, PartnerRequest } from './Entitys';
import type { DemoRequestArg, PartnerRequestArg } from './Entitys';
import landingInstance, { setInstanceUrl } from '../../libs/landingInstance';

const sendDemoRequest = async (postData: DemoRequestArg): Promise<unknown> => {
  const demoRequestData = new DemoRequest(postData);
  const response = await landingInstance().post('/landing', demoRequestData.getData());

  return response;
};

const sendPartnerRequest = async (postData: PartnerRequestArg): Promise<unknown> => {
  const partnerRequestData = new PartnerRequest(postData);
  const response = await landingInstance().post('/landing/partner', partnerRequestData.getData());

  return response;
};

const sendBecomePartnerRequest = async (postData: JoinFormArg): Promise<unknown> => {
  const joinFormData = new JoinFormRequest(postData);
  const response = await landingInstance().post('/landing/partner', joinFormData.getData());

  return response;
};

const sendReportSampleForm = async (postData: JoinFormArg): Promise<unknown> => {
  const requestSampleData = new JoinFormRequest(postData);
  const response = await landingInstance().post('/landing/sample', requestSampleData.getData());

  return response;
};

const sendFreeRatingForm = async (postData: JoinFormArg): Promise<unknown> => {
  const freeRatingData = new JoinFormRequest(postData);
  const response = await landingInstance().post('/landing/v2', freeRatingData.getData());

  return response;
};

export { Tools } from './Entitys';

export default {
  sendDemoRequest,
  sendPartnerRequest,
  sendBecomePartnerRequest,
  sendReportSampleForm,
  sendFreeRatingForm,
  setInstanceUrl,
};
